<script setup lang="ts">
import {
  Alert,
  AlertBody,
  Button,
  Modal,
  ModalBody,
  Separator,
  Spinner,
  TextField,
} from '@wartek-id/belajar-id-components'
import { useVModel } from '@vueuse/core'
import { useGA, useResetPassword } from '@/composables'
import type { ResetPasswordMethod } from '@/services'

interface Props {
  modelValue?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'success'): void
}>()

const isOpen = useVModel(props, 'modelValue', emit)

const router = useRouter()

const {
  loading,
  error,
  handlSendOtp: sendOtp,
  email,
  emailEligible,
  phone,
  phoneEligible,
  account,
} = useResetPassword()

const { trackActivity } = useGA()

const handlSendOtp = (method: ResetPasswordMethod) => {
  trackActivity({
    activity_type: 'SEND_OTP_RESET_PASSWORD',
    page_title: 'Belajar.id | Home Page',
    username: account.value.lookup?.email,
    dob: account.value.lookup?.dob,
    method,
  })

  sendOtp({
    method,
    onSuccess(res) {
      trackActivity({
        activity_type: 'SEND_OTP_RESET_PASSWORD_SUCCESS',
        page_title: 'Belajar.id | Home Page',
        username: account.value.lookup?.email,
        dob: account.value.lookup?.dob,
        method,
        session_id: res.session_id,
      })

      isOpen.value = false
      emit('success')

      if (method === 'phone')
        router.push('/reset-password/otp')
    },
    onError(e) {
      trackActivity({
        activity_type: 'SEND_OTP_RESET_PASSWORD_FAILED',
        page_title: 'Belajar.id | Home Page',
        username: account.value.lookup?.email,
        dob: account.value.lookup?.dob,
        method,
        error: e.response?.data?.error?.message || e.message,
      })
    },
  })
}

const isModalHelpOpen = ref(false)

const store = useStore()

const onModalClose = () => {
  store.commit('resetPassword/reset')
}

const cantAccessData = () => {
  isModalHelpOpen.value = true

  trackActivity({
    activity_type: 'CANT_ACCESS_DATA_RESET_PASSWORD',
    page_title: 'Belajar.id | Home Page',
    username: account.value.lookup?.email,
    dob: account.value.lookup?.dob,
  })
}
</script>

<template>
  <Modal
    v-model="isOpen"
    class="modal-reset-password"
    :width="360"
    @close="onModalClose"
  >
    <ModalBody class="py-5">
      <img
        src="/images/img-padlock-lock-access.png"
        alt="img-padlock-lock-access"
        class="mb-3 w-[45px]"
      >
      <h3 class="font-semibold text-[22px] mb-2">
        Reset Kata Sandi Anda
      </h3>
      <p class="uppercase font-semibold text-13 text-subdued mb-3">
        Pilih cara mendapatkan kode untuk mereset kata sandi Anda di bawah.
      </p>
      <Separator class="mb-5" />
      <p class="text-subdued text-13 mb-3">
        Data tertera di bawah di ambil dari database data pokok
        pendidikan(DAPODIK) Indonesia.
      </p>

      <Alert v-if="error" variant="critical" class="mb-3">
        <AlertBody>
          {{ error }}
        </AlertBody>
      </Alert>

      <div v-if="loading" class="text-center">
        <Spinner variant="primary" />
      </div>

      <div v-else>
        <TextField
          name="privateEmail"
          label="Email Pribadi"
          wrapper-class="mb-4"
          :placeholder="email"
          :disabled="!emailEligible"
          readonly
        />

        <div class="space-y-2">
          <Button
            variant="ultimate"
            type="button"
            full-width
            size="lg"
            :disabled="!emailEligible"
            @click="handlSendOtp('email')"
          >
            Kirim Kode ke Email Pribadi
          </Button>

          <Button
            type="button"
            variant="quiet"
            full-width
            size="lg"
            @click="cantAccessData"
          >
            Tidak ada akses ke data ini?
          </Button>
        </div>
      </div>

      <!-- modal help -->
      <ResetPasswordHelp
        v-model="isModalHelpOpen"
        @zendesk:open="isOpen = false"
      />
    </ModalBody>
  </Modal>
</template>
